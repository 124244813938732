import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import "./condiciones-legales.scss";

export default function Error() {
    return (
        <React.Fragment>
            <SEO title="Error"/>
            <Layout pageId="privacy-policy">
                <div className="privacy-policy-container">
                    <h1>Error en el proceso</h1>
                    <h4>
                        Ha habido un error en el procesamiento de su petición
                    </h4>
                    <p>
                        Si el error persiste no dude en ponerse en contacto con
                        los administradores en{" "}
                        <a href="mailto:admin@negligenciasdeabogados.net">
                            admin@negligenciasdeabogados.net
                        </a>
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </Layout>
        </React.Fragment>
    );
}
